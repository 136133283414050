import { getSrc } from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'

import { SchemaFaq } from '~/components/seo-schemas'
import SEO from '~/components/shared/Seo'
import { BlogArticlePageQueryResult } from '~/templates/blog/Article'
import { FAQ } from '~/types/data-array'
import { WithRequired } from '~/types/helper'
import { BlogPageContext } from '~/types/page-contex'
import { fileToImageLikeData, toIsoString } from '~/utils'

type ArticleMetaProps = Pick<BlogPageContext, 'ratingData'> & {
  data: BlogArticlePageQueryResult
}

const ArticleMeta = ({ data, ratingData }: ArticleMetaProps) => {
  const {
    title,
    authors,
    category,
    url,
    publication_date,
    modified_date,
    meta_title,
    meta_description,
    og_image,
    structured_data,
    structured_data_video,
    rating,
    articlefaqs,
  } = data.strapiArticle as WithRequired<
    Queries.STRAPI_ARTICLE,
    'title' | 'url' | 'meta_title' | 'og_image' | 'category'
  >

  const publicationDateIso = toIsoString(new Date(publication_date as string))
  const modifiedDateIso = toIsoString(new Date(modified_date as string))

  const schemaArticleFaq =
    articlefaqs?.map(
      (articlefaq): FAQ => ({
        answer: articlefaq?.answer?.data?.childMarkdownRemark?.html || '',
        question: articlefaq?.question || '',
      }),
    ) || []

  const seoSchema =
    schemaArticleFaq.length > 0
      ? SchemaFaq({ dataContent: schemaArticleFaq })
      : null

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        urlImage={getSrc(fileToImageLikeData(og_image.localFile)) as string}
        url={`https://www.codica.com/blog/${url}/`}
        meta={[
          {
            name: 'robots',
            content:
              'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
          },
        ]}
        headline={title}
        dateModified={modifiedDateIso}
        datePublished={publicationDateIso}
        author={authors?.map((a) => ({
          '@type': 'Person',
          name: a?.name,
          sameAs: [`https://www.codica.com/blog/author/${a?.slug}/`],
        }))}
        isBlogPost
        structuredData={structured_data}
        structuredDataVideo={structured_data_video}
        isBreadcrumbs
        secondLevel="Blog"
        secondLevelPath="/blog/"
        thirdLevel={category.name}
        thirdLevelPath={`/blog/categories/${kebabCase(category.name || '')}/`}
        current={title}
        currentPath={`/blog/${url}/`}
      />

      <link
        crossOrigin="anonymous"
        href="https://codica-images-production.s3.eu-central-1.amazonaws.com/"
        rel="preconnect"
      />
      <link
        crossOrigin="anonymous"
        href="https://codica-images-production.s3.eu-central-1.amazonaws.com/"
        rel="dns-prefetch"
      />

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'CreativeWorkSeason',
          name: title,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: ratingData.average,
            ratingCount: (ratingData?.count || 0) + (rating || 0),
            bestRating: '5',
            worstRating: '1',
          },
        })}
      </script>

      {seoSchema && <script type="application/ld+json">{seoSchema}</script>}
    </>
  )
}

export default ArticleMeta

// extracted by mini-css-extract-plugin
export var recentArticles = "cL_t1";
export var recentArticles__category = "cL_vc";
export var recentArticles__img = "cL_t4";
export var recentArticles__link = "cL_t3";
export var recentArticles__post = "cL_t2";
export var recentArticles__postClamp = "cL_vb";
export var recentArticles__postDate = "cL_t6";
export var recentArticles__postDescriprion = "cL_t9";
export var recentArticles__postInfo = "cL_t5";
export var recentArticles__postStats = "cL_t7";
export var recentArticles__postTitle = "cL_t8";
export var recentArticles__title = "cL_vd";